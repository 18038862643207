import React from 'react'
import { Helmet } from 'react-helmet'
import { Layout, Seo } from '@global'
import { Form } from '@pages/contact'

const IndexPage = () => (
  <Layout>
    <Seo
      title="Let's Launch Something Great | Stellr Web | Cody, WY"
      description="Ready to start your project? Have any questions about web development? Send us an email. We'd love to help!"
    />
    <Helmet encodeSpecialCharacters={false}>
      <script type='application/ld+json'>
        {`
					{
						"@context": "https://schema.org/",
						"@type": "BreadcrumbList",
						"itemListElement": [{
							"@type": "ListItem",
							"position": 1,
							"name": "Contact",
							"item": "https://www.stellrweb.com/contact"
						}]
					}
        `}
      </script>
    </Helmet>
    <Form />
  </Layout>
)

export default IndexPage
