import React from 'react'
import {
  Button,
  Container,
  Title,
  FlexContainer,
  FlexItem,
  Gradient,
  Label,
} from '@UI'
import { Fade } from '@animations'
import * as styles from './form.module.scss'

const Form = () => {
  return (
    <Container section isSection className={styles.container}>
      <Gradient />

      <Container wrapperSmOnMd>
        <Fade y={20}>
          <Title tag='h1' center className={styles.title}>
            What happens next?
          </Title>
        </Fade>

        <Fade delay={0.2}>
          <FlexContainer
            alignStart
            collapseOnMd
            className={styles.flexContainer}
          >
            <FlexItem>
              <Container className={styles.textContainer} textBlock>
                <Label>Let's Build Something That Makes a Difference.</Label>
                <p>
                  If you're ready to get started, please fill out the form with
                  a brief description about the project you have in mind, and we
                  will set up a call or an in-person meeting to go over the
                  details and find out if we're a good fit.
                </p>
                <p>
                  If you're not sure what you need, if you have questions about
                  what we do, or if you need some advice about your digital
                  presence, fill out the form and we will get back to you as
                  soon as possible.
                </p>
                <p>
                  Email us directly at{' '}
                  <a href='mailto:info@stellrweb.com'>info@stellrweb.com</a>
                </p>
              </Container>
            </FlexItem>

            <FlexItem>
              <form
                className={styles.form}
                name='contact'
                method='post'
                data-netlify='true'
                data-netlify-honeypot='bot-field'
                action='/success?no-cache=1'
              >
                <input type='hidden' name='form-name' value='contact' />
                <input type='hidden' name='bot-field' />

                <div className={styles.section}>
                  <label htmlFor='name' className={styles.label}>
                    Full Name
                  </label>
                  <input
                    type='text'
                    name='name'
                    id='name'
                    className={styles.input}
                    required
                  />
                </div>

                <div className={styles.section}>
                  <label htmlFor='email' className={styles.label}>
                    Email
                  </label>
                  <input
                    type='email'
                    name='email'
                    id='email'
                    required
                    className={styles.input}
                  />
                </div>

                <div className={styles.section}>
                  <label htmlFor='message' className={styles.label}>
                    Message
                  </label>
                  <textarea
                    name='message'
                    id='message'
                    className={styles.textarea}
                  />
                </div>

                <Button cta type='submit' name='submit'>
                  Send Email
                </Button>
              </form>
            </FlexItem>
          </FlexContainer>
        </Fade>
      </Container>
    </Container>
  )
}

export default Form
